import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import List from '../../components/Common/List'
import NewsQuoteBlock from '../../components/NewsQuoteBlock'

import * as s from '../../pages/news.module.scss'

const InstrumentiBimManageraDlyaAutomatizaciiProverok: React.FC = () => {
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="Инструменты для автоматизации работы BIM-менеджера"
        description="Проверка сводных BIM-моделей с помощью плагина для Navisworks, который позволяет быстро в автоматическом режиме проверять комплексные модели на соответствие требованиям"
      />
      <NewsSection
        title={`SIGNAL TOOLS: Checker - автоматизированный BIM-менеджер в\u00A0вашей компании.`}
        dateData={{
          date: '08.02.2024',
        }}
      >
        <p className={s.text}>
          Технологии SIGNAL позволяют реализовать цифровые проекты в
          строительстве. Однако очень важное условие возможности использовать
          данные на стройплощадке - их корректность, полнота и качество по
          итогам проектирования. Именно поэтому большое внимание там необходимо
          уделить качеству проектов/моделей. Это может быть как задачей
          исполнителя (проектная организация) перед передачей данных заказчику,
          так и процессом заказчика, который хочет автоматизировать проверку на
          соответствие своим информационным требованиям (EIR).
        </p>
        <p className={s.text}>
          BIM-менеджер в компании достаточно многозадачный человек, он должен
          уметь эффективно общаться, управлять данными, выявлять потенциальные
          проблемы в проекте, координировать действия между различными командами
          и отделами, оставаться в рамках бюджета, соответствовать нормативным
          требованиям и нормам, обеспечивать точность данных проекта, а также
          идти в ногу с развитием отрасли.
        </p>
        <p className={s.text}>
          Часть из этих задач можно автоматизировать или упростить, например
          проверку данных проекта. В BIM-моделях содержится огромное количество
          информации, которое в ручную проверить достаточно сложно, а в реалиях
          отрасли модели постоянно меняются и отследить изменения не всегда
          возможно.
        </p>
        <p className={s.text}>
          Для автоматизации этих задач мы разработали инструмент Checker в
          SIGNAL TOOLS.
        </p>
        <p className={s.text}>
          Checker - комплексный инструмент, для проверки информационных моделей
          по заданным правилам, позволяющий проверять модель на:
        </p>
        <List>
          <li>Наличие свойств</li>
          <li>Наличие свойств для групп элементов</li>
          <li>Наличие одного из свойств</li>
          <li>Заполненность значений свойств для групп элементов</li>
          <li>Проверка на пересечения (Clash Detective)</li>
          <li>Наличие элементов</li>
        </List>
        <p className={s.text}>
          Найденные ошибки отображаются в зоне “Результатов проверок”. Откуда
          можно перейти в элемент модели с ошибкой и выдать к нему замечания.
          Результаты проверок можно выгрузить в Excel или XML и, например, файл
          XML уже подгрузить в Revit , чтобы быстро найти элементы с ошибками и
          исправить их. Функция сравнения результатов, позволит сравнить текущую
          проверку с предыдущей, чтобы увидеть, какие замечания были исправлены.
        </p>
        <p className={s.text}>
          Важно отметить, что все проверки запускаются буквально в пару кликов
          или с помощью заранее подготовленных шаблонов.
        </p>
        <h2 className={s.textTitle}>Проверки в Checker</h2>
        <h3 className={cn(s.text, 'font-w-600')}>Наличие свойств</h3>
        <p className={s.text}>
          Проверяет заполненность значения проверочного свойства. Подходит для
          проверки свойств, которые должны быть заполнены у всех элементов
          модели.
        </p>
        <p className={s.text}>
          Например, проверим, что свойство “Код по классификатору” заполнено у
          всех элементов.
        </p>
        <div className="flex">
          <StaticImage
            width={750}
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-1.png"
            title="Проверка, что свойство “Код по классификатору” заполнено у всех элементов"
            alt="Проверка, что свойство “Код по классификатору” заполнено у всех элементов"
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>
          Проверка, что свойство “Код по классификатору” заполнено у всех
          элементов.
        </p>
        <h3 className={cn(s.text, 'font-w-600')}>
          Наличие свойств для групп элементов
        </h3>
        <p className={s.text}>
          Данная функция проверяет, что у всех элементов модели, содержащих
          условное свойство с определенным значением, заполнены проверочные
          свойства. (В данном контексте условное свойство - это свойство,
          которое задает начальное условие: Если свойство “Категория” имеет
          значение “Стены”. Проверочные свойства - это свойства, которые
          проверяются на наличие заполненного значения.)
        </p>
        <div className="flex">
          <StaticImage
            width={750}
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-2.png"
            title="Проверка, что у элементов с кодом классификатора “Е2.4.2” и “Е2.3.1.1” заполнены проверочные свойства ”Этаж” и “Корпус”"
            alt="Проверка, что у элементов с кодом классификатора “Е2.4.2” и “Е2.3.1.1” заполнены проверочные свойства ”Этаж” и “Корпус”"
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>
          Проверка, что у элементов с кодом классификатора “Е2.4.2” и “Е2.3.1.1”
          заполнены проверочные свойства ”Этаж” и “Корпус”.
        </p>
        <p className={s.text}>
          Правила к данной проверке удобнее создавать в таблице Excel:
        </p>
        <div className="flex">
          <StaticImage
            width={750}
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-3.png"
            title="Пример заполненного шаблона к проверке"
            alt="Пример заполненного шаблона к проверке"
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>Пример заполненного шаблона к проверке.</p>
        <p className={s.text}>
          Также можно проверять по другим условиям, например по Коду
          классификатора
        </p>
        <div className="flex">
          <StaticImage
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-4.png"
            title="Пример заполненного шаблона к проверке по Коду классификатора"
            alt="Пример заполненного шаблона к проверке по Коду классификатора"
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>
          Пример заполненного шаблона к проверке по Коду классификатора.
        </p>
        <h3 className={cn(s.text, 'font-w-600')}>Наличие одного из свойств</h3>
        <p className={s.text}>
          Проверяет заполненность значения проверочного свойства, при этом для
          каждого проверочного свойства задается отдельный список возможных
          вариантов свойств. Например, когда объем может быть в свойствах
          “Объем”, ”Volume” или “Dynamo_Объем”. Если хотя бы один из вариантов
          имеется в элементе и заполнен, то проверочное свойство считается
          заполненным.
        </p>
        <div className="flex">
          <StaticImage
            width={750}
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-5.png"
            title="Проверка, что свойство “Объем” заполнено у всех элементов"
            alt="Проверка, что свойство “Объем” заполнено у всех элементов"
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>
          Проверка, что свойство “Объем” заполнено у всех элементов.
        </p>
        <h3 className={cn(s.text, 'font-w-600')}>
          Заполненность значений свойств для групп элементов
        </h3>
        <p className={s.text}>
          Проверяет, что у всех элементов модели, содержащих условное свойство с
          определенным значением, проверочные свойства имеют конкретное
          значение.
        </p>
        <div className="flex">
          <StaticImage
            width={750}
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-6.png"
            title="Проверка, что у элементов с условным свойством “Категория”, имеющим значение “Стены”, проверочное свойство “Толщина” равно “0,200”"
            alt="Проверка, что у элементов с условным свойством “Категория”, имеющим значение “Стены”, проверочное свойство “Толщина” равно “0,200”"
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>
          Проверка, что у элементов с условным свойством “Категория”, имеющим
          значение “Стены”, проверочное свойство “Толщина” равно “0,200”.
        </p>
        <h3 className={cn(s.text, 'font-w-600')}>
          Проверка на пересечения (Clash Detective)
        </h3>
        <p className={s.text}>
          Функция похожа, на подобную в Navisworks, но она автоматизирует
          процессы, которые вручную создаются в Navis bпроверяет на пересечения
          уже созданные поисковые наборы. Можно проверять элементы на
          пересечение с другими элементами или на самопересечения.
        </p>
        <p className={s.text}>
          Создайте поисковые наборы с элементами с помощью команды
          “Стандартные”, которые требуется проверить на пересечения.
        </p>
        <List className="my-4">
          <li>
            В настройках SIGNAL создайте "Группы наборов", в которые добавьте
            поисковые наборы с элементами.
          </li>
        </List>
        <div className="flex">
          <StaticImage
            width={750}
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-7.png"
            title="Результат проверки на пересечения в Checker"
            alt="Результат проверки на пересечения в Checker"
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>
          Результат проверки на пересечения в Checker.
        </p>
        <h3 className={cn(s.text, 'font-w-600')}>Наличие элементов</h3>
        <p className={s.text}>
          Проверяет наличие элементов в модели по заданным свойствам.
        </p>
        <p className={s.text}>
          Например, проверим, что в модели количество конвекторов составляет
          1050 штук.
        </p>
        <div className="flex">
          <StaticImage
            width={750}
            src="../../assets/images/news/instrumenti-bim-managera-dlya-automatizacii-proverok/image-8.png"
            title="Проверка, что в модели количество конвекторов составляет 1050 штук."
            alt="Проверка, что в модели количество конвекторов составляет 1050 штук."
            className="mt-4 mx-auto"
          />
        </div>
        <p className={s.imageText}>
          Проверка, что в модели количество конвекторов составляет 1050 штук.
        </p>
        <NewsQuoteBlock
          className="mt-16 mb-16"
          name="Евгений Селяев"
          position="Начальник отдела внедрения BIM ИНТЕКО"
          text={`“С помощью Checker мы выполняем проверки по всем нашим проектам (Каждые две недели), что\u00A0позволило существенно сократить необходимые ресурсы и время”`}
          avatar={images.avatar.childImageSharp.gatsbyImageData}
        />
        <h3 className={cn(s.text, 'font-w-600')}>Заключение:</h3>
        <p className={s.text}>
          Инструмент Checker позволяет быстро проверить модель на наличие
          ошибок, пересечений или недостающей информации. Более того, при работе
          с большими моделями или сборками, Checker может осуществлять проверку
          без участия специалиста в фоновом, роботизированном режиме, пока
          решаются другие параллельные задачи.
        </p>
      </NewsSection>
    </>
  )
}

export default InstrumentiBimManageraDlyaAutomatizaciiProverok

const imagesQuery = graphql`
  query {
    avatar: file(
      relativePath: {
        eq: "news/instrumenti-bim-managera-dlya-automatizacii-proverok/Evgeny Silyaev.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 50, placeholder: BLURRED)
      }
    }
  }
`
